<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body h-100">
        <div class="mb-3">
          <h3>
            พนักงาน {{ inputsnode.totalRows }} คน
          </h3>
          <div class="separator" />
        </div>
        <div class="row justify-content-between">
          <div class="col-md-3 col-sm-12 mb-1 d-flex col-xl-2">
            <b-button
              variant="success"
              style="height: 38px;"
              block
              @click="onclickRouterAdd"
            >
              เพิ่มพนักงาน
            </b-button>
          </div>
          <div class="col-md-5 col-sm-6 mb-1">
            <!-- <div class="d-flex justify-content-center">
              <b-button
                :class="{ active: searchTerm === 0 }"
                :variant="outline[0]"
                class="btBetween"
                size="sm"
                @click="setRole(0)"
              >
                ทั้งหมด
              </b-button>
              <b-button
                :class="{ active: searchTerm === 1 }"
                :variant="outline[1]"
                class="btBetween"
                size="sm"
                @click="setRole(1)"
              >
                หัวหน้า
              </b-button>
              <b-button
                :class="{ active: searchTerm === 2 }"
                :variant="outline[2]"
                size="sm"
                class="btBetween"
                @click="setRole(2)"
              >
                ฝ่ายขาย
              </b-button>
            </div> -->
          </div>
          <div class="col-md-4 col-xl-3 col-sm-6 mb-1">
            <b-form-group style="margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="datas"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(firstName)="data">
            {{ `${data.item.firstName}   &nbsp;&nbsp; ${data.item.lastName}` }}
          </template>

          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-warning mr-1"
                type="button"
                @click="onClickEditAdd(data.item.code)"
              >
                แก้ไข
              </button>
              <button
                class="btn btn-danger mr-1"
                type="button"
                @click="removeEmployer(data.item._id)"
              >
                ลบ
              </button>
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลพนักงาน</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลพนักงาน</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          v-if="totalItem > 0"
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BButtonGroup
} from 'bootstrap-vue'
import router from '@/router'

export default defineComponent({
  name: 'employee_list',
  components: {
    BTable,
    BAvatar,
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BButtonGroup
  },
  watch: {
  },
  computed: {
    totalItem () {
      return this.inputsnode.totalRows
    }
  },
  data () {
    return {
      searchTerm: '',
      items: [],
      datas: [],
      outline: ['primary', 'outline-primary', 'outline-primary'],
      fields: [
        {
          key: 'code', label: 'รหัสพนักงาน', tdClass: 'text-center', thClass: 'text-center', thStyle: 'min-width: 160px', sortable: true
        },
        {
          key: 'firstName', label: 'ชื่อพนักงาน', thStyle: 'min-width: 250px'
        },
        { key: 'telephoneNumber', label: 'เบอร์โทร', thStyle: 'min-width: 150px' },
        { key: 'role.name', label: 'ตำแหน่ง', thStyle: 'min-width: 150px' },
        { key: 'button', label: '', thClass: 'text-center', thStyle: 'min-width: 220px' }

      ],
      inputsnode: {
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }

    }
  },
  methods: {
    setRole (val) {
      if (val === 0) {
        this.searchTerm = ''
      } else if (val === 1) {
        this.searchTerm = 'หัวหน้า'
      } else if (val === 2) {
        this.searchTerm = 'ฝ่ายขาย'
      }

      for (let i = 0; i < 3; i += 1) {
        if (i === val) {
          this.outline[i] = 'primary'
        } else {
          this.outline[i] = 'outline-primary'
        }
      }
    },
    removeEmployer (id) {
      if (id !== '' && id !== undefined) {
        this.$swal({
          title: 'คุณต้องการจะลบข้อมูลนี้?',
          text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-success mr-1',
          confirmButtonText: 'ยืนยัน',
          cancelButtonClass: 'btn-danger',
          cancelButtonText: 'ยกเลิก'
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('employer/DeleteEmployer', { id })
              .then(response => {
                if (response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'พนักงาน',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: response.data.message
                    }
                  })
                  this.loader()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'พนักงาน',
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: response.data.message
                    }
                  })
                }
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.message
                  }
                })
              })
          }
        })
      }
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    onclickRouterAdd () {
      router.push('/employee/add')
    },
    onClickEditAdd (code) {
      this.$router.push({ path: `/employee/add/${code}` })
    },
    loader () {
      this.datas = []
      this.$store.dispatch('employer/getEmployerList').then(result => {
        console.log(result)
        this.datas = result.data.items
        this.inputsnode.totalRows = this.datas.length
      })
    }

  },
  created () {
    this.loader()
  }
})
</script>

<style scoped>
.b-group {
  width:100% !important;
}
.btBetween{
  height: 2.714rem !important;
  height: 25px;margin-right: 3px;
}
</style>
